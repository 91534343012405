import { z } from 'zod'
import { DimensionValueTypes } from './types'

export const MAX_VALUE_FOR_DIMENSIONS = 10000
export const MIN_VALUE_FOR_DIMENSIONS = 0.01

export const dimensionsSchemaValidator = z.object({
  [DimensionValueTypes.L]: z
    .number({
      invalid_type_error: 'Every side must be more than 0',
      required_error: 'Every side must be more than 0',
    })
    .min(
      MIN_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    )
    .max(
      MAX_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    ),
  [DimensionValueTypes.W]: z
    .number({
      invalid_type_error: 'Every side must be more than 0',
      required_error: 'Every side must be more than 0',
    })
    .min(
      MIN_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    )
    .max(
      MAX_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    ),
  [DimensionValueTypes.H]: z
    .number({
      invalid_type_error: 'Every side must be more than 0',
      required_error: 'Every side must be more than 0',
    })
    .min(
      MIN_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    )
    .max(
      MAX_VALUE_FOR_DIMENSIONS,
      `Side length must be in range 0.01-${MAX_VALUE_FOR_DIMENSIONS} inches`,
    ),
})
