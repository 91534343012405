export enum DimensionValueTypes {
  L = 'length',
  W = 'width',
  H = 'height',
}

export type Value = null | number

export type FeesForm = {
  category: number | undefined
  [DimensionValueTypes.L]: Value
  [DimensionValueTypes.W]: Value
  [DimensionValueTypes.H]: Value
  vas: boolean
}

export enum PeriodTypes {
  ninety_days = 'ninety_days',
  ninety_days_peak = 'ninety_days_peak',
}
