import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce, isNil, uniq } from 'lodash'
import type { Callbacks } from 'rc-field-form/lib/interface'
import { TreeSelect, Form, InputNumber, Flex, Checkbox } from 'antd'
import { Category, FeeEntry, FeeType } from '@r1-oas/billing-calculator'
import { Typography } from '../Typography'
import { billingCalculatorApi } from '../../api/billing-calculator'
import { DimensionValueTypes, FeesForm } from './types'
import {
  dimensionsSchemaValidator,
  MAX_VALUE_FOR_DIMENSIONS,
  MIN_VALUE_FOR_DIMENSIONS,
} from './validators'
import './styles.css'

const DimensionSuffix = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center justify-center bg-transparent px-3 py-0.5 text-xs text-[#16293F]">
    {children}
  </div>
)
const ErrorText = ({ children }: { children: React.ReactNode }) => (
  <div className="py-1.5 text-xs text-[#F44336]">{children}</div>
)

type TreeCategory = Category & { selectable: boolean; children: TreeCategory[] }

const makeCategoriesSelectable = (categories: Category[]): TreeCategory[] =>
  categories.map(category => ({
    ...category,
    selectable: category.children.length === 0,
    children: makeCategoriesSelectable(category.children),
  }))

const INITIAL_VALUES: FeesForm = {
  category: undefined,
  length: null,
  width: null,
  height: null,
  vas: false,
}

interface CalculateFeesArgs {
  length: number
  width: number
  height: number
  category: number
}

export const FeesCalculator = () => {
  const [categories, setCategories] = useState<TreeCategory[]>([])
  useEffect(() => {
    const fetchCategories = async () => {
      await billingCalculatorApi.getCategoryTree().then(res => {
        if (res.success) {
          const treeCategories = makeCategoriesSelectable(res.body.rootCategories)
          setCategories(treeCategories)
        }
      })
    }

    fetchCategories()
  }, [])

  // Form fields
  const [form] = Form.useForm<FeesForm>()
  const isCategorySelected = Boolean(Form.useWatch('category', form))
  const vasField = Form.useWatch('vas', form)

  // Fees and total
  const [fees, setFees] = useState<FeeEntry[]>([])
  const supplyChainFee = fees.find(fee => fee.feeType === FeeType.SupplyChainFee)
  const vasFee = fees.find(fee => fee.feeType === FeeType.VasFee)
  const total = useMemo(() => {
    const supplyChainFeeValue = supplyChainFee?.cost ?? 0
    const vasFeeValue = vasFee?.cost ?? 0
    if (vasField) {
      return Number(supplyChainFeeValue + vasFeeValue).toFixed(2)
    }

    return supplyChainFeeValue
  }, [supplyChainFee, vasFee, vasField])

  // Calculate fees handler
  const handleCalculateFees = useCallback(
    async ({ category, length, width, height }: CalculateFeesArgs) => {
      const response = await billingCalculatorApi.calculateFees({
        body: {
          categoryId: category,
          length,
          width,
          height,
        },
      })

      if (response.success) {
        setFees(response.body.fees)
        return
      }
      setFees([])
    },
    [],
  )
  const debouncedCalculateFees = useMemo(
    () => debounce(handleCalculateFees, 1000),
    [handleCalculateFees],
  )

  // Validation results and form handlers
  const [dimensionsValidationResults, setDimensionsValidationResults] = useState<Array<string>>([])

  const onValuesChange: Callbacks<FeesForm>['onValuesChange'] = (changedValues, values) => {
    // Get all field names in the form
    const dimensionsFields = [DimensionValueTypes.W, DimensionValueTypes.L, DimensionValueTypes.H]
    // Check if all fields have been touched
    const areDimensionsFieldsTouched = dimensionsFields.every(
      fieldName => form.isFieldTouched(fieldName), // Check if the field has been touched
    )

    if (areDimensionsFieldsTouched) {
      // Validate dimension fields once all are touched
      const { width, height, length } = form.getFieldsValue()
      const validationResult = dimensionsSchemaValidator.safeParse({ width, height, length })

      if (!validationResult.success) {
        const validationErrors = uniq(validationResult.error.issues.map(issue => issue.message))

        setDimensionsValidationResults(validationErrors)
      } else {
        setDimensionsValidationResults([])
      }
    }

    const isNullishValueExists = Object.values(values).some(value => isNil(value))
    if (!isNullishValueExists) {
      debouncedCalculateFees(
        values as {
          length: number
          width: number
          height: number
          category: number
        },
      )
    }
  }

  const handleClearCategory = () => {
    setFees([])
  }

  return (
    <div className="mt-5 flex max-[890px]:flex-col">
      {/* Left Section */}
      <Form
        className="flex grow flex-col items-start p-10 ring-1 ring-gray-200 min-[890px]:w-1/2 min-[890px]:rounded-l-3xl max-[890px]:rounded-t-3xl"
        initialValues={INITIAL_VALUES}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Typography.H5 className="mb-3">Dimensions</Typography.H5>
        <Flex align="center" className="max-[890px]:flex-col max-[890px]:gap-4 w-full">
          <Form.Item className="mb-0 w-full" name={DimensionValueTypes.L}>
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-right w-full"
              min={MIN_VALUE_FOR_DIMENSIONS}
              max={MAX_VALUE_FOR_DIMENSIONS}
              addonAfter={<DimensionSuffix>L</DimensionSuffix>}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full" name={DimensionValueTypes.W}>
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-both w-full"
              min={MIN_VALUE_FOR_DIMENSIONS}
              max={MAX_VALUE_FOR_DIMENSIONS}
              addonAfter={<DimensionSuffix>W</DimensionSuffix>}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full" name={DimensionValueTypes.H}>
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-left w-full"
              min={MIN_VALUE_FOR_DIMENSIONS}
              max={MAX_VALUE_FOR_DIMENSIONS}
              addonAfter={<DimensionSuffix>H</DimensionSuffix>}
            />
          </Form.Item>
          <DimensionSuffix>in.</DimensionSuffix>
        </Flex>
        <Flex vertical>
          {dimensionsValidationResults.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ErrorText key={`error-${index}`}>{error}</ErrorText>
          ))}
        </Flex>
        <div className="divider mt-3" />
        <Typography.H5 className="mb-3 mt-3">Category</Typography.H5>

        <Form.Item
          className="mb-0 w-full"
          name="category"
          rules={[{ required: true, message: 'Category should be chosen' }]}
        >
          <TreeSelect
            showSearch
            allowClear
            size="large"
            className="w-full"
            treeNodeFilterProp="categoryName"
            fieldNames={{ label: 'categoryName', value: 'categoryId' }}
            treeData={categories}
            onClear={handleClearCategory}
          />
        </Form.Item>
        <div className="divider mt-6" />
        <div className="flex flex-col items-start">
          <Form.Item name="vas" valuePropName="checked">
            <Checkbox className="mt-6" disabled={!isCategorySelected}>
              Value-Added Services
            </Checkbox>
          </Form.Item>
        </div>
      </Form>

      {/* Right Section */}
      <div className="flex flex-col justify-between bg-primary-navy-dark pt-12 ring-1 ring-gray-900 min-[890px]:w-1/2 min-[890px]:rounded-r-3xl max-[890px]:rounded-b-3xl">
        <div className="flex flex-col px-12">
          <Typography.H5 className="mt-10 mb-3 text-white">One-Time Fees</Typography.H5>
          <div className="flex grow justify-between text-xl text-white py-5 border-t border-b border-slate-600">
            <div>Supply Chain Services</div>
            <div className="font-bold">
              {supplyChainFee ? `$${supplyChainFee.cost ?? 0}` : 'N/A'}
            </div>
          </div>
          <div className="flex grow justify-between text-xl text-white py-5 border-b border-slate-600">
            <div>Value-Added Services</div>
            <div className="font-bold">{vasField && vasFee ? `$${vasFee.cost ?? 0}` : 'N/A'}</div>
          </div>

          <div className="mt-3 text-white text-xs">
            For reCommerce, a 10% fee will apply towards each sale&apos;s final price plus any
            applicable marketplace fees. See full list of fees
          </div>
        </div>
        <div className="bg-primary-dark mt-5 flex gap-12 rounded-br-3xl p-12 justify-center">
          <div className="flex flex-col">
            <p className="font-bold text-white">Total one-time fees estimate</p>
            <div className="flex items-center justify-center">
              <Typography.H2 className="text-white">{total}</Typography.H2>
              <p className="ml-3 text-white">per unit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
